import React, { useMemo } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
}

const Image = ({ image, className, isData = false, imgStyle }) => {
  const imageData = useMemo(() => {
    return isData ? getGatsbyImageData(image._id, {}, sanityConfig) : image.gatsbyImageData
  }, [isData, image])

  return <GatsbyImage className={className} imgStyle={imgStyle} image={imageData} alt={image.originalFilename} />
}

export default Image
