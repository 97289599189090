export const formatParams = params => {
  if (!params) return ""

  let paramList = (params = params.replace("?", "").split("&"))

  let formattedParams = {}

  paramList.forEach(param => {
    const [key, value] = param.split("=")
    formattedParams[key] = value
  })

  return JSON.stringify(formattedParams)
}
