import axios from "axios"

export const postscriptSubscriberRegister = async payload => {
  return await axios.post("https://api.postscript.io/v1/subscribers", payload, {
    headers: {
      Authorization: "Bearer " + process.env.GATSBY_POSTSCRIPT_API_KEY,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
}
