import { useStaticQuery, graphql } from "gatsby"

const useBlackFriday = () => {
  const { site } = useStaticQuery(
    graphql`
      query LayoutSiteSettingsQuery {
        site: sanitySiteSettings {
          countdownBgColor {
            hex
          }
          displayBanner
          bannerText
          displayCountdown
          countdownLeftText
          countdownLeftLink
          countdownRightText
          countdownRightLink
          countdownDate
          enableDiscount
          enableGiftCard
          enableUpSellPopup
        }
      }
    `
  )

  // Enable discount shows products with tiered discount options
  // If false then products are shown as standard with quantity pickers

  // Manual switch below for local dev / testing

  // site.enableDiscount = false

  return site
}

export default useBlackFriday
