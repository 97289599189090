export const gtagProductMapping = product => ({
  id: product.kmkSku || product.sku || "",
  name: product.name,
  product_id: product.shopifyProductId || product.product_id || "",
  variant: product.variant || product.kmkTitle || "",
  variant_id: product.variant_id || "",
  price: product.kmkPrice || "",
  sku: product.kmkSku || product.sku || "",
  quantity: product.kmkQuantity,
  image: product.kmkImage?.asset?.gatsbyImageData?.images?.fallback?.src || "",
  brand: "Kiss My Keto",
  category: product?.categories?.join(", ") || product?.type,
  position: product?.position || "",
  list: location?.pathname,
})
const gtag = (event, data) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({ event, ...data })
}
export default gtag

export const gtagEventForProduct = (eventName, productData, shopifyVariants) => {
  gtag(eventName, {
    event_id: "collection" + Math.floor(Date.now() / 1000),
    ecommerce: {
      currencyCode: "USD",
      impressions: shopifyVariants
        ? productData
            .map((p, index) => {
              let selVariant = p.flavors?.[0] || {}
              selVariant = {
                ...selVariant,
                ...shopifyVariants.filter(variant => variant.sku === p.flavors?.[0].sku)?.[0],
              }
              return {
                ...p,
                variant: selVariant.title,
                variant_id: selVariant?.shopifyId?.replace("gid://shopify/ProductVariant/", ""),
                kmkPrice: selVariant.price,
                kmkQuantity: 1,
                sku: selVariant?.sku,
                position: index + 1,
              }
            })
            .map(gtagProductMapping)
        : productData,
    },
  })
}
