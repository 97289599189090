// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-template-index-js": () => import("./../../../src/templates/PageTemplate/index.js" /* webpackChunkName: "component---src-templates-page-template-index-js" */),
  "component---src-templates-product-page-index-js": () => import("./../../../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */)
}

