import { formatParams } from "./formatParams"
import Cookies from "js-cookie"

export const generateNotes = params => {
  const notes = [
    {
      // Attribution information
      key: "_elevar_visitor_info", // this key and value are static.
      value: formatParams(params),
    },
  ]

  // Get cookies for elevar notes
  const fbCookie = Cookies.get("_fbp")
  const gaCookie = Cookies.get("_ga")

  if (fbCookie) {
    notes.push({
      key: "_elevar__fbp",
      value: fbCookie,
    })
  }

  if (gaCookie) {
    notes.push({
      key: "_elevar__ga",
      value: gaCookie,
    })
  }

  // GA4 cookie information
  const cookies = Cookies.get()
  // GA4 cookies are stored in a format as follows _ga_EQCN43L3M1: cookieValue
  const id = Object.keys(cookies).filter(key => key.includes("_ga_"))

  if (id.length) {
    // You'll need to retrieve the suffix portion of this string
    const elevarId = id[0].replace("_ga_", "")

    // Append it to the elevar string
    notes.push({
      key: `_elevar__ga_${elevarId}`,
      value: Cookies.get(`_ga_${elevarId}`),
    })
  }

  return notes
}
