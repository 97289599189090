import React from "react"
import Image from "components/atoms/Image"

import { FLAVOR_UNITS } from "consts"
import { DISCOUNTS } from "consts/index"
const moment = require("moment-timezone")

export const cn = (...args) => {
  return args.filter(Boolean).join(" ")
}

export const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals)
}

export const currencyFormat = amount => {
  const integer = Math.floor(parseFloat(amount))
  const decimal = (parseFloat(amount).toFixed(2) + "").split(".")[1]
  return (
    <>
      {integer}.{decimal}
    </>
  )
}

export const SelectOptionFlavors = flavors => {
  const optionTemplate = []

  flavors.forEach(flavor => {
    // flavor.isFlavor &&
    optionTemplate.push({
      component: (
        <div className="flavor-option">
          <Image image={flavor?.flavorIcon?.asset} className="select-flavors" />
          <div className="flavor-text">{flavor?.flavor}</div>
        </div>
      ),
      label: flavor?.flavor,
      value: flavor?.sku,
    })
  })

  return optionTemplate
}

export const SelectOptionQty = (unit, allowDiscount = true) => {
  const optionTemplate = []
  const units = FLAVOR_UNITS[unit]

  units?.forEach((unit, idx) => {
    optionTemplate.push({
      component: (
        <div className="qty-option">
          <div>{idx + 1 + ` ${unit}`}</div>
          {idx && allowDiscount ? <div>Get {3 * idx}% off</div> : ""}
        </div>
      ),
      label: idx + 1,
      value: idx + 1,
    })
  })

  return optionTemplate
}

export const SelectVarietyOptionQty = (selFlavor, flavors, hideDiscount = false) => {
  // if (selFlavor !== 0) return []
  const optionTemplate = []
  const units = FLAVOR_UNITS[selFlavor.unit]
  const varietyDiscounts = calDiscountFlavor(selFlavor, flavors)
  units?.forEach((unit, idx) => {
    selFlavor?.subQtyItems.length &&
      optionTemplate.push({
        component: (
          <div className="qty-option">
            {!selFlavor?.subQtyItems?.length && <div>{idx + 1 + ` ${unit}`}</div>}
            {selFlavor?.subQtyItems?.length && (
              <div>{selFlavor?.subQtyItems[idx]?.subQty + " " + selFlavor?.subQtyItems[idx]?.subQtyUnit}</div>
            )}
            {varietyDiscounts[idx]?.value === 0 || !hideDiscount ? (
              ""
            ) : (
              <div>Get {varietyDiscounts[idx] ? varietyDiscounts[idx].value : 15}% off</div>
            )}
          </div>
        ),
        label: selFlavor?.subQtyItems?.length
          ? selFlavor?.subQtyItems[idx]?.subQty + " " + selFlavor?.subQtyItems[idx]?.subQtyUnit
          : idx + 1,
        value: idx + 1,
      })
  })

  return optionTemplate
}

export const calDiscountFlavor = (selFlavor, flavors) => {
  const numberOfFlavors = flavors.filter(flavor => flavor.isFlavor).length
  const varietyPackSize = Math.floor(numberOfFlavors / (flavors.length - numberOfFlavors))
  if (!selFlavor?.isFlavor && !selFlavor?.isBundle) {
    let varietyDiscounts
    if (varietyPackSize === 2) {
      varietyDiscounts = [
        { text: "10% off", value: 10 },
        { text: "15% off", value: 15 },
        { text: "20% off", value: 20 },
        { text: "20% off", value: 20 },
      ]
    } else if (varietyPackSize === 3) {
      varietyDiscounts = [
        { text: "10% off", value: 10 },
        { text: "15% off", value: 15 },
        { text: "15% off", value: 15 },
        { text: "15% off", value: 15 },
      ]
    } else {
      varietyDiscounts = [
        { text: "10% off", value: 10 },
        { text: "15% off", value: 15 },
        { text: "15% off", value: 15 },
        { text: "15% off", value: 15 },
      ]
    }
    return varietyDiscounts
  } else {
    return DISCOUNTS
  }
}

export const sortByName = (collection, isAsc) => {
  return collection
    .filter(o => o && o.name !== undefined)
    .sort(({ name: a }, { name: b }) => {
      if (isAsc) {
        return a < b ? -1 : a > b ? 1 : 0
      } else {
        return a > b ? -1 : a < b ? 1 : 0
      }
    })
}

export const sortByPrice = (collection, isAsc) => {
  const tempCollection = []
  collection.map(o => {
    o.price = o.flavors[0].price
    tempCollection.push(o)
  })
  return tempCollection
    .filter(o => o && o.flavors[0].price !== undefined)
    .sort(({ price: a }, { price: b }) => {
      if (isAsc) {
        return a < b ? -1 : a > b ? 1 : 0
      } else {
        return a > b ? -1 : a < b ? 1 : 0
      }
    })
}

export const sortByDate = (collection, isAsc) => {
  return collection
    .filter(o => o && o._updatedAt !== undefined)
    .sort(({ _updatedAt: a }, { _updatedAt: b }) => {
      if (isAsc) {
        return a < b ? -1 : a > b ? 1 : 0
      } else {
        return a > b ? -1 : a < b ? 1 : 0
      }
    })
}

export const sortByBestSell = collection => {
  return collection
    .filter(o => o && o.bestSell !== undefined)
    .sort(({ bestSell: a }, { bestSell: b }) => (a > b ? -1 : a < b ? 1 : 0))
}

export const SortProducts = (sortKey, prods) => {
  switch (sortKey) {
    case "featured":
      return prods
    case "best-sell":
      return sortByBestSell(prods)
    case "a-z":
      return sortByName(prods, true)
    case "z-a":
      return sortByName(prods, false)
    case "price-high-low":
      return sortByPrice(prods, false)
    case "price-low-high":
      return sortByPrice(prods, true)
    case "date-new-old":
      return sortByDate(prods, false)
    case "date-old-new":
      return sortByDate(prods, true)
    default:
      return prods
  }
}

export const useFlavorAsProduct = products => {
  const flavorsAsProduct = []
  products.forEach(simpleProduct => {
    simpleProduct?.flavors.forEach((flavorItem, index) => {
      if (flavorItem?.bahaveAsProduct) {
        const flavorProduct = {
          ...simpleProduct,
        }

        if (index !== 0) {
          flavorProduct.flavorIndex = index
          flavorsAsProduct.push(flavorProduct)
        }
      }
    })
  })
  return [...products, ...flavorsAsProduct]
}

export const isFutureDate = date => {
  const nowDateUTC = moment().tz("UTC").format()

  return moment(nowDateUTC).isAfter(date)
}

export const blackFridayTimer = endDate => {
  const targetPstTime = moment(endDate).tz("America/Los_Angeles").format()
  const countDownDate = new Date(targetPstTime).getTime()

  const nowPstTime = moment().tz("America/Los_Angeles").format()
  const now = new Date(nowPstTime).getTime()

  const distance = countDownDate - now
  const days = Math.floor(distance / (1000 * 60 * 60 * 24))
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  return {
    days,
    hours,
    minutes,
    seconds,
    distance,
  }
}

export const getCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(";").shift()
}

export const crateUtmUrl = utmData => {
  let urlPart = ""
  for (const [key, value] of Object.entries(utmData)) {
    urlPart = urlPart + `&${key}=${value}`
  }
  return urlPart
}

export const filterRecommendedProducts = (products, productName) => {
  const productNameValue = switchProductName(productName)
  const filteredProducts = products.filter(item => item.title.toLowerCase().includes(productNameValue.toLowerCase()))
  return filteredProducts
}

export const switchProductName = productName => {
  let productNameValue = productName.includes("bread")
    ? "cookies"
    : productName.includes("gummies")
    ? "keto bars"
    : productName.includes("keto bars")
    ? "gummies"
    : productName.includes("keto dark chocolate")
    ? "keto bars"
    : productName.includes("cookies")
    ? "keto bars"
    : productName.includes("granola")
    ? "keto bread"
    : productName.includes("keto baking mix")
    ? "cookies"
    : productName.includes("noodles")
    ? "keto bone broth"
    : productName.includes("mct oil powder")
    ? "cookies"
    : productName.includes("bread crumbs")
    ? "bread"
    : "cookies"
  return productNameValue
}
