// # This isn't component This is for generating options for product flavors
export const FRQ_WEEKS = [{ desc: "15 days" }, { desc: "30 days" }, { desc: "45 days" }, { desc: "60 days" }]

export const DISCOUNTS = [
  { text: "\u00A0", value: 0 },
  { text: "10% off", value: 10 },
  { text: "15% off", value: 15 },
  { text: "20% off", value: 20 },
]

export const FLAVOR_UNITS = {
  loaf: ["loaf", "loaves", "loaves", "loaves"],
  box: ["box", "boxes", "boxes", "boxes"],
  bottle: ["bottle", "bottles", "bottles", "bottles"],
  pack: ["pack", "packs", "packs", "packs"],
  jar: ["jar", "jars", "jars", "jars"],
  tub: ["tub", "tubs", "tubs", "tubs"],
  card: ["card", "cards", "cards", "cards"],
}

export const FLAVOR_UNIT = {
  loaves: "loaf",
  loaf: "loaf",
  boxes: "box",
  box: "box",
  bottles: "bottle",
  bottle: "bottle",
  packs: "pack",
  pack: "pack",
  jars: "jar",
  jar: "jar",
  tubs: "tub",
  tub: "tub",
  cards: "card",
  card: "card",
  bags: "bag",
  bag: "bag",
  bars: "bar",
  bar: "bar",
}
