import React, { useState, useEffect, createContext } from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import gtag, { gtagProductMapping } from "../utils/gtag"
import { postscriptSubscriberRegister } from "./api"
import { crateUtmUrl } from "consts/helper"
import useBlackFriday from "hooks/useBlackFriday"
import { generateNotes } from "/src/utils/elevar/generateNotes"
import { createStorefrontApiClient } from "@shopify/storefront-api-client"

const isBrowser = typeof window !== "undefined"

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_CUSTOM_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const storefrontClient = createStorefrontApiClient({
  storeDomain: `http://${process.env.GATSBY_SHOPIFY_STORE_URL}`,
  apiVersion: "2024-10",
  publicAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
})

const defaultValues = {
  isAddingProductInCart: false,
  loading: false,
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  lineItems: [],
  bundleItems: [],
  isBlackFri: false,
  enableGiftCard: false,
}

export const StoreContext = createContext(defaultValues)

export const StoreProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const { displayCountdown, enableDiscount, enableGiftCard, enableUpSellPopup } = useBlackFriday()
  const [isOpenCart, setIsOpenCart] = useState(false)
  const [isReBuyPopupOpen, setReBuyPopupOpen] = useState(false)
  const [isAddingProductInCart, setIsAddingProductInCart] = useState(false)
  const [cartProds, setCartProds] = useState([])
  const [lineItems, setLineItems] = useState([])
  const [bundleItems, setBundleItems] = useState([])
  const [isBlackFri, setIsBlackFri] = useState(false)
  const [headerShow, setHeaderShow] = useState(true)

  useEffect(() => {
    if (!isReBuyPopupOpen) {
      setIsOpenCart(prevState => !prevState)
    }
  }, [isReBuyPopupOpen])

  useEffect(() => {
    setIsBlackFri(displayCountdown)
    setIsOpenCart(false)
  }, [])

  useEffect(() => {
    if (!lineItems?.length) {
      const localData = JSON.parse(localStorage.getItem("cartData"))
      if (localData?.length) {
        setLineItems(localData)
      }
    }
  })

  useEffect(() => {
    const tempCartProds = []
    lineItems.map(item => {
      if (item.kmkSku !== "Gift Card") {
        tempCartProds.push(Number(item.product_id))
      }
    })
    setCartProds(tempCartProds)
  }, [lineItems.length])

  const addToCart = cartItem => {
    const line_items = lineItems
    line_items.push(cartItem)
    setLineItems([...line_items])
    localStorage.setItem("cartData", JSON.stringify(line_items))
    gtag("dl_add_to_cart", {
      event_id: (cartItem.kmkSku || cartItem.sku || "") + Math.floor(Date.now() / 1000).toString(),
      ecommerce: {
        currencyCode: "USD",
        add: {
          products: [gtagProductMapping(cartItem)],
        },
      },
    })

    if (!enableUpSellPopup) {
      setIsOpenCart(true)
    }

    let utmUrl = ""
    const localStorageUtmData = JSON.parse(localStorage.getItem("utm"))

    if (localStorageUtmData) {
      utmUrl = crateUtmUrl(localStorageUtmData)
    }

    const mainGraphqlUrl = process.env.GATSBY_SHOPIFY_UTM_REQUEST_URL
    const fullUtmUrl = `${mainGraphqlUrl}${utmUrl}`

    if (JSON.parse(localStorage.getItem("cartData")).length > 0 && localStorageUtmData) {
      fetch(fullUtmUrl)
    }
  }

  const updateCart = (updatedItem, oldVariantId) => {
    const line_items = lineItems.map(item => {
      if (item.variant_id == oldVariantId) {
        item = updatedItem
      }
      return item
    })
    setLineItems(line_items)
    localStorage.setItem("cartData", JSON.stringify(line_items))
  }

  const removeFromCart = product => {
    const removeIdx = []
    lineItems.map((item, idx) => {
      if (item.variant_id == product.variant_id) {
        removeIdx.push(idx)
        gtag("dl_remove_from_cart", {
          event_id: (product.kmkSku || product.sku || "") + Math.floor(Date.now() / 1000),
          ecommerce: {
            currencyCode: "USD",
            remove: {
              actionField: { list: location.pathname },
              products: [gtagProductMapping(product)],
            },
          },
        })
      }
    })
    const line_items = lineItems.filter((item, idx) => idx !== removeIdx[0])
    setLineItems(line_items)
    localStorage.setItem("cartData", JSON.stringify(line_items))
  }

  const handleCheckout = () => {
    const isSubscription = lineItems?.filter(item => item.kmkIsSubscribe)?.length
    if (isSubscription) {
      handleSubscribeCheckout().then(urlString => {
        window.open(urlString, "_self")
      })
    } else {
      handleShopifyCheckout().then(updatedUrl => {
        window.open(updatedUrl.toString(), "_self")
      })
    }
  }

  const getUtmParams = () => {
    let utmUrl = ""
    const localStorageUtmData = JSON.parse(localStorage.getItem("utm"))

    if (localStorageUtmData) {
      utmUrl = crateUtmUrl(localStorageUtmData)
    }
    return utmUrl
  }

  const handleSubscribeCheckout = async () => {
    setLoading(true)

    const lines = lineItems.map(item => {
      return {
        sellingPlanId: item.selling_plan,
        merchandiseId: item.storefrontId,
        quantity: item.quantity,
      }
    })

    const res = await storefrontClient.request(
      `mutation cartCreate($input: CartInput) {
        cartCreate(input: $input) {
          cart {
            id
            checkoutUrl
            lines(first: 10) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                    }
                  }
                }
              }
            }
            cost {
              subtotalAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }`,
      {
        variables: {
          input: {
            lines,
          },
        },
      }
    )

    return res.data.cartCreate.cart.checkoutUrl
  }

  const handleShopifyCheckout = async () => {
    setLoading(true)

    const shopifyLineItems = lineItems.map(item => {
      return {
        variantId: item.storefrontId,
        quantity: item.quantity,
      }
    })
    const newCheckout = await client.checkout.create()
    const checkoutdata = await client.checkout.addLineItems(newCheckout.id, shopifyLineItems)

    // Store tracking data in notes attribute - Elevar
    const trackingParams = localStorage.getItem("trackingParams")
    const input = { customAttributes: generateNotes(trackingParams) }
    await client.checkout.updateAttributes(newCheckout.id, input)

    // sending cart ID to Refersion
    const checkoutUrl = checkoutdata.webUrl.split("/")
    const token = checkoutUrl[checkoutUrl.length - 1].split("?")
    if (token?.length && token[0]) {
      if (window?._refersion) {
        window._refersion(function () {
          window._rfsn._addCart(token[0])
        })
      }
    }

    // sending cart ID to Refersion
    return checkoutdata?.webUrl
  }

  const handlePostscriptSubscriberRegister = async phone_number => {
    setLoading(true)
    try {
      const response = await postscriptSubscriberRegister({
        keyword: "KISSMYKETO",
        phone_number,
      })
      if (response?.data?.success) {
        alert("Successfully signed up!")
      }
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        setIsOpenCart,
        addToCart,
        updateCart,
        removeFromCart,
        handleCheckout,
        setIsBlackFri,
        setBundleItems,
        setLineItems,
        handlePostscriptSubscriberRegister,
        loading,
        isOpenCart,
        isAddingProductInCart,
        cartProds,
        lineItems,
        bundleItems,
        isBlackFri,
        headerShow,
        setHeaderShow,
        enableGiftCard,
        enableDiscount,
        isReBuyPopupOpen,
        setReBuyPopupOpen,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
